<template>
  <div class="bg" :class="{ twoType: isUse }">
    <el-card class="box-card" v-if="isShowExtendBtnDropdown">
      <div slot="header" class="clearfix">
        <div class="refresh-date"></div>
        <div class="right-box">
          <DeleteEdit
            class="menu-extend-dropdown"
            :isEdit="false"
            :cpnName="PreviewData.name"
            :cpnId="PreviewData.i"
            :cpnObject="PreviewData"
          />
        </div>
      </div>
      <div class="content_center">
        <cc-renderer
          :componentData="componentData"
          :canvasStyleData="canvasStyleData"
        />
      </div>
    </el-card>
    <cc-renderer
      class="myrenderer"
      v-else
      v-loading="loading"
      :pageApi="pageApi"
      :componentData="componentData"
      :canvasStyleData="canvasStyleData"
      :echoData="echoData"
    />
  </div>
</template>

<script>
/**
 * 预览组件
 */
import DeleteEdit from "@/views/homePage/components/DeleteEdit.vue";

export default {
  props: {
    api: {
      type: String,
      default: "",
    },
    PreviewData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    // 监听路由是否变化
    // 解决普通应用程序,切换自定义菜单页面不刷新的问题; 控制台应用程序做了路由缓存不能走这里,走这里会造成缓存失效
    $route(n) {
      if (n.path == "/injectionComponent") {
        this.getCustomPageDetail();
      }
    },
  },
  data() {
    return {
      // 自定义页面的组件信息
      componentData: [],
      // 自定义页面画布信息
      canvasStyleData: {},
      // 自定义页面回显数据
      echoData: {},
      isUse: false,
      pageApi: "",
      loading: false,
    };
  },
  created() {
    this.isUsed();
  },
  mounted() {
    // 这个是用于通过ccdk打开时传的初始化数据回显 如menu1_self 、menu2_self
    if (this.$route.params && this.$route.params.pageId) {
      this.echoData = localStorage.getItem(this.$route.params.pageId) || {};
    }
    // 这个是用于通过ccdk打开时传的初始化数据回显 如tab_self 、tab_blank
    if (this.$route.query && this.$route.query.pageId) {
      this.echoData = localStorage.getItem(this.$route.query.pageId) || {};
    }
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
    // 获取自定义页面数据
    this.getCustomPageDetail();
  },
  beforeDestroy() {
    // this.componentData = []
    // this.echoData = {}
  },
  methods: {
    isUsed() {
      if (this.$route.query.button == "Home") {
        this.isUse = true;
      } else {
        this.isUse = false;
      }
    },
    getCustomPageDetail() {
      this.loading = true;
      this.pageApi =
        this.api || this.$route.query.page || this.$route.params.page;
      let dialogTitle = this.$route.query.dialogTitle;
      document.title = this.$setTitle(dialogTitle);
      let parameter = {
        pageApi: this.pageApi,
      };
      this.$devHttp
        .postFormat(
          `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/detailCustomPage`,
          parameter
        )
        .then((res) => {
          if (res.returnCode == 200) {
            this.componentData = JSON.parse(res.data.pageContent);
            this.canvasStyleData = JSON.parse(res.data.canvasStyleData);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: { DeleteEdit },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      return (
        this.homePageObject.type == "personal" ||
        (this.homePageObject.type == "profile" &&
          this.homePageObject.isCustomToRolePage)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .box-card {
  height: 100% !important;
  .content_center {
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
  }
}
::v-deep .el-card__body {
  width: 100%;
  height: calc(100% - 54px) !important;
  padding: 20px;
  padding-top: 0 !important;
  padding-bottom: 6px;
  padding-right: 0;
}
</style>

<style lang="scss" scoped>
.bg {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
}
.twoType {
  width: 100%;
  height: 100%;
}
.myrenderer {
  height: 100%;
}
</style>
