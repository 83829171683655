<template slot-scope="scope">
  <el-popover
    placement="bottom-start"
    width="88"
    trigger="hover"
    popper-class="operationClass"
  >
    <div class="moreOperation">
      <div v-if="isEdit" @click="editCpn">
        <!-- 编辑 -->
        {{ $t("pagecreator_page_button_edit") }}
      </div>
      <div @click="deleteCpn">
        <!-- 删除 -->
        {{ $t("component_setup_tabs_label_delete") }}
      </div>
    </div>
    <svg class="icon operationImg1" aria-hidden="true" slot="reference">
      <use href="#icon-operation1"></use>
    </svg>
  </el-popover>
</template>
<script>
export default {
  props: {
    cpnId: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    cpnName: {
      type: String,
      default: "",
    },
    // 当前点击的组件的对象（包含组件的所有信息，each组件请求的数据）
    cpnObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      // 
    });
  },
  methods: {
    // 编辑组件
    editCpn() {
      let tmpObj = {};
      tmpObj.isDelete = this.cpnObject.isDelete;
      tmpObj.isEdit = this.cpnObject.isEdit;
      tmpObj.isNewEvent = this.cpnObject.isNewEvent;
      tmpObj.isNewTask = this.cpnObject.isNewTask;
      let tepArr = [
        {
          id: 1,
          isNewTask: "true",
        },
        {
          id: 2,
          isNewEvent: "true",
        },
        {
          id: 3,
          isEdit: "true",
        },
        {
          id: 4,
          isDelete: "true",
        },
      ];
      // 根据返回的参数，判断是那个是要选中的，返回对象，需要取id
      let tmpCardChoosebtnTmpObject = tepArr.filter((item) => {
        if (item.isNewTask === tmpObj.isNewTask) {
          return item;
        } else if (item.isNewEvent === tmpObj.isNewEvent) {
          return item;
        } else if (item.isEdit === tmpObj.isEdit) {
          return item;
        } else if (item.isDelete === tmpObj.isDelete) {
          return item;
        }
      });
      // 最终获取到选择按钮展示的id，回显传id即可
      let tmpCardChoosebtnTmpObjectId = tmpCardChoosebtnTmpObject.map(
        (item) => item.id
      );
      let cpnObject = JSON.parse(JSON.stringify(this.cpnObject));
      // CardChoosebtnIdArr即要回显的id数组
      cpnObject.CardChoosebtnIdArr = tmpCardChoosebtnTmpObjectId;
      // 编辑卡片视图方法全局发射到index页面
      if (
        this.cpnObject.iscustom === "1" &&
        this.cpnObject.type !== "PersonalPageCpn"
      ) {
        this.$bus.$emit("editCpn", {
          cpnObject,
          cpnId: this.cpnId,
          cpnName: this.cpnName,
        });
      }
      // 如果添加组件时那些固定的组件就是用这下面的方法
      if (this.cpnObject.iscustom === "0") {
        this.$bus.$emit("editPinned", {
          cpnObject,
          cpnId: this.cpnId,
          cpnName: this.cpnName,
        });
      }
      // 编辑仪表板页面方法全局发射到index页面
      if (
        this.cpnObject.iscustom === "1" &&
        this.cpnObject.type === "PersonalPageCpn"
      ) {
        this.$bus.$emit("instrumentPanel", {
          cpnObject,
          cpnId: this.cpnId,
          cpnName: this.cpnName,
        });
      }
    },
    // 删除组件
    deleteCpn() {
      if (this.cpnObject.iscustom === "1") {
        this.$bus.$emit("deleteCpn", this.cpnId, this.cpnName);
      }
      //
      if (this.cpnObject.iscustom === "0") {
        this.$bus.$emit(
          "deleteFixedComponents",
          this.cpnId,
          this.cpnName,
          this.cpnObject.relatedid
        );
      }
    },
  },
};
</script>
<style lang="scss">
.el-popover.operationClass {
  padding: 10px 0 !important;
  min-width: 0;
  .moreOperation {
    color: #333333;
    font-size: 12px;
    div {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: #006dcc;
        color: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scope>
  .operationImg1{
    width:21px;
    height: 21px;
  }
  .right-box {
    align-items: center;
  }
</style>